import { Injectable } from "@angular/core";
import { MailingApi, MailingListDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";
import { currentProjectId$ } from "../../../app.subscriptions";
import { ApiClientFactory } from "../../api-client/services/api-client.factory";

@Injectable({ providedIn: "root" })
export class MailingListStore extends PageableCrudStore<MailingListDto> {
  constructor(private apiClientFactory: ApiClientFactory) {
    super({ loadOnDeletion: true });
    void currentProjectId$.subscribe(() => this.reset());
  }

  protected async loadModelById(id: string): Promise<MailingListDto> {
    const res = await this.apiClientFactory.project().get(MailingApi).getMailingList({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.apiClientFactory.project().get(MailingApi).deleteMailingList({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.apiClientFactory.project().get(MailingApi).deleteManyMailingLists({ ids });
    return res.data;
  }
  protected async createModel(createMailingList: MailingListDto): Promise<MailingListDto> {
    const res = await this.apiClientFactory.project().get(MailingApi).createMailingList({ createMailingList });
    return res.data;
  }
  protected async patchModel(id: string, mailingList: Partial<MailingListDto>): Promise<MailingListDto> {
    const res = await this.apiClientFactory.project().get(MailingApi).patchMailingList({ id, mailingList });
    return res.data;
  }
  protected async putModel(mailingList: MailingListDto): Promise<MailingListDto> {
    const res = await this.apiClientFactory
      .project()
      .get(MailingApi)
      .putMailingList({ id: mailingList.id, mailingList });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<MailingListDto>> {
    const res = await this.apiClientFactory.project().get(MailingApi).getMailingLists(query);
    return res.data;
  }
}
